<template>
  <div class="pole-main" id="autofitBox">
    <div class="pole-header" :style="{backgroundImage: `url('${img.pole.header.bg}')`}" @click="clickOther">
      <div class="header-side"></div>
      <div :class="['header-menu', currentMenuName == 'home' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'home' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('home')">首页</div>
      <div :class="['header-menu', currentMenuName == 'pole' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'pole' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('pole')">智慧灯杆</div>
      <div :class="['header-menu', currentMenuName == 'gis' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'gis' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('gis')">GIS地图</div>
      <div class="header-center"></div>
      <!-- <div :class="['header-menu', currentMenuName == 'asset' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'asset' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('asset')">资产运维</div> -->
      <div :class="['header-menu', currentMenuName == 'analyse' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'analyse' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('analyse')">数据分析</div>
      <div :class="['header-menu', currentMenuName == 'business' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'business' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('business')">运营管理</div>
      <div :class="['header-menu', currentMenuName == 'system' ? 'menu-selected' : '']" :style="{backgroundImage: currentMenuName == 'system' ? `url('${img.pole.header.btn}')` : ''}" @click="switchMenu('system')">系统管理</div>
      <div class="header-side flex-end">
        <img style="width: 30px; height: 30px; margin-right: 8px" :src="img.headerUserIcon2" />
        <HeaderDropDown />
      </div>
    </div>
    <div class="pole-body">
      <div class="pole-slider-space">
      </div>
      <div class="pole-slider" :style="{width: `${sliderWidth}px`}" @mouseover="hoverSlider">
        <div :class="['slider-item', currentMenuName == item.code ? 'item-selected' : '']" v-for="(item, idx) in sliderMenus" :key="idx" @click="switchMenu(item.code)">
          <div :class="['slider-flag', currentMenuName == item.code ? 'flag-selected' : '']"></div>
          <img :src="currentMenuName == item.code ? img.pole.slider.active[item.code] : img.pole.slider.menu[item.code]" :title="item.name" />
          <span v-if="sliderWidth > 100">{{item.name}}</span>
        </div>
      </div> 
      <div v-if="currentMenuName != 'traffic'" class="pole-content" @click="clickOther">
        <router-view></router-view>
      </div>
      <div v-else class="pole-content">
        <iframe src="https://www.hippopx.com/" style="width:100%;height:100%;"></iframe>
      </div>
    </div> 
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HeaderDropDown from '@/views/common/menu/HeaderDropDown'
// import autofit from 'autofit.js'
export default {
  name: 'Home',
  components: {
    HeaderDropDown,
  },
  data() {
    return {
      timeout: null,
      sliderWidth: 72,
    }
  },
  computed: {
    ...mapState('auth', ['customerId', 'agentChangeValue', 'currentMenuName', 'sliderMenus', 'urls']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
    currentMenuName(){
      let menu = this.currentMenuName;
      if(menu == 'analyse' || menu == 'asset'){
        menu = 'pole';
      }
      // this.$store.commit('group/setPoleDeviceTypes', menu)
      // this.$store.commit('group/selectNodeById', {nodeId: 0, type: 'root'})
      this.$store.dispatch('group/getPoleTreeData', menu)
    },
    agentChangeValue() {
      this.debounceInitData();
    },
    $route: {
      handler: function (route) {
        // console.log('router handler', route.query, route.path)
        this.query = route.query;
        for (let key in this.urls) {
          let url = this.urls[key];
          if (url.indexOf(route.path) != -1) {
            // this.selectMenu = key;
            this.$store.commit('auth/setPoleMenuName', key);
            return;
          }
        }
      },
      immediate: true
    },
  },
  created: function () {
    this.$store.commit('auth/setAppType', 'pole');
  },
  mounted: function () {
      // this.setAutofit()
  },
  methods: {
    // setAutofit(){
    //   autofit.init({
    //     dh:878,
    //     dw:1920,
    //     el:'#autofitBox',
    //     resize:true,
    //     // ignore:['.center-middle']
    //   },false)
    // },
    hoverSlider: function () {
      this.sliderWidth = 145;
      // console.log('hover slider', this.sliderWidth)
    },
    clickOther: function () {
      this.sliderWidth = 72;
      // console.log('click other', this.sliderWidth)
    },
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.initData, 500);
    },
    initData: function () {
      if (this.customerId == 0) return;
      let menu = this.currentMenuName;
      if(menu == 'analyse' || menu == 'asset'){
        menu = 'pole';
      }
      // this.$store.dispatch('group/getGroupAndPoles', menu).then(res => {
      this.$store.dispatch('group/getPoleTreeData', menu)
      // this.$store.dispatch('cmd/queryZhuhaiSanrun');
    },
    switchMenu: function (menu) {
      if (this.currentMenuName == menu) return;
      this.$store.commit('auth/setPoleMenuName', menu);
      let url = this.urls[menu];
      if (url && url.length > 0) {
        this.$router.replace(url);
      }
    },
  },
}
</script>
<style scoped>
.pole-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.pole-header {
  height: 92px;
  flex: none;
  background-position: 100% 103px;
  background-color: #0b1022;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.header-center {
  width: 700px;
  flex: none;
  position: relative;
}
.header-center img {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  margin-top: 55px;
}
.header-menu {
  width: 150px;
  flex: none;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 20px;
  font-family: titleBody;
  font-weight: 400;
  color: #9ebee9;
  line-height: 85px;
  cursor: pointer;
}
.menu-selected {
  color: #ffffff;
}
.header-side {
  /* border: solid 1px red; */
  width: 600px;
  flex: auto;
  display: flex;
  align-items: center;
}
.flex-end {
  justify-content: flex-end;
}
.pole-body {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  display: flex;
}
.pole-slider-space {
  /* border: solid 1px red; */
  width: 72px;
  flex: none;
}
.pole-slider {
  /* border: solid 1px red; */
  z-index: 100;
  position: absolute;
  left: 0;
  top: 92px;
  bottom: 0;
  flex: none;
  background: linear-gradient(0deg, #0b1020, #0e2d58, #0b1020);
}

.slider-item {
  display: flex;
  align-items: center;
  height: 70px;
  cursor: pointer;
}
.slider-flag {
  width: 4px;
  height: 54px;
  background: transparent;
  border-radius: 0px 2px 2px 0px;
  display: flex;
  margin-right: 16px;
}
.flag-selected {
  background: #06cdf8;
}
.slider-item span {
  margin-left: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #7796bf;
}
.item-selected {
  /* border: solid 1px rgb(255, 208, 0); */
  /* background: linear-gradient(90deg, #094363, #0B1A33); */
  background: linear-gradient(90deg, #054f78, #054f78, #091f40);
}
.item-selected span {
  color: #ffffff;
}
.pole-content {
  /* border: solid 1px red; */
  background-color: #e9edf4;
  width: 720px;
  flex: auto;
  overflow: hidden;
}
</style>